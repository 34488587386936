import {Layout, Row, Space} from "antd";
import {useState} from "react";
import {About, CurrentUserAvatar, ContactUsLink, ContactUsModal, GuideLink,
    HomeLink, LogOut, OtherResources} from "@digatex/digatex-ui-lib";
import Api from '../../services/Api'

function sendEmail(formData) {
    return Api.post(`/feedback/contact_us`, formData);
}

export function Header() {
    const [showContactForm, setShowContactForm] = useState(false)

    return (
        <Layout.Header style={{padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <HomeLink/>
            <div style={{marginLeft: 'auto'}}>
                <Row>
                    <Space size={"middle"}>
                        <About/>
                        <OtherResources/>
                        <GuideLink url={process.env.PUBLIC_URL + '/files/1000-100-DAT-00007 08 Quick reference guide - DIGATEX Engineering Hub.pdf'}/>
                        <ContactUsLink show={() => setShowContactForm(true)}/>
                        <LogOut />
                        <CurrentUserAvatar/>
                    </Space>
                </Row>
            </div>
            <ContactUsModal show={showContactForm} close={() => setShowContactForm(false)} sendEmail={sendEmail}/>
        </Layout.Header>
    );
}